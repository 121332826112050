<template>
  <div>
    <div class="myHeader">
      <div class="nav">
        <img ref="imgShow" class="navImg" src="../../static/giftIndex/return3.png" alt="" @click="goBack()">
        <p class="main_title">礼品专区</p>
        <p class="ExchangeRecord" @click="ExchangeRecord()">兑换记录</p>
      </div>
      <div class="search">
        <div class="searchLeft" @click="showPopup(1)">
          <img src="../../static/giftIndex/moreImg.png" alt="">
          <p>分类</p>
        </div>
        <div class="searchRight">
          <input v-model="searchMsg" type="text" placeholder="搜索礼品名称" @keyup.enter="goSearch()">
          <img src="../../static/giftIndex/search.png" alt="" @click="goSearch()">
        </div>
      </div>
      <div class="myMoney">
        <div class="myMoneyLeft">
          <p><span>我的{{ moneyName }}</span>{{ money }}</p>
        </div>
        <!-- <div class="myMoneyRight">
          <button>去赚筑龙币</button>
        </div> -->
      </div>
    </div>
    <van-popup v-model="show" position="top" round :style="{ height: '60%' }">
      <div class="popupAll">
        <div class="popupHeader">
          <img src="@/static/images/my_index/back_black.png" alt="" @click="showPopup(2)">
          <p class="popupTitle">选择分类</p>
          <span></span>
        </div>
        <div class="popupSearch">
          <div class="Search_ipt">
            <div class="SearchBox">
              <span v-for="(item,index) in flList" :key="index" class="pushClass" @click="delItem(item,index)">{{ item.name }}
                <van-icon name="cross" style="padding:0 0 0 8px;" />
              </span>
            </div>
            <span class="searchSpan" @click="showPopup(2)">取消</span>
          </div>
        </div>
        <div class="partition2"></div>
        <div class="classification">
          <p class="classification_p">分类</p>
          <div class="classification_item">
            <p v-for="(item,index) in CategoryList" :key="index" :class="{pGrey:item.checked === 0,pRed:item.checked === 1}" @click="CategoryListPush(item,index)">{{ item.name }}</p>
          </div>
        </div>
        <!-- <div class="classification">
          <p class="classification_p">价格区间</p>
          <div class="classification_item">
            <p>筑龙原创</p>
            <p>筑龙原创</p><p>筑龙原创</p><p>筑龙原创</p><p>筑龙原创</p>
          </div>
        </div> -->
        <div class="popupBtn">
          <div class="popupBtn_item">
            <button class="Reset" @click="Resets()">重置</button>
            <button class="complete" @click="showPopup(3)">完成<span>(已选{{ flList.length }})</span></button>
          </div>
        </div>
      </div>
    </van-popup>

    <div class="gift_main">
      <div v-show="is_zlList" class="main_list">
        <div class="main_top">
          <div class="top_left">
            <p>筑龙原创</p>
            <img src="../../static/giftIndex/titBg.png" alt="">
          </div>
          <div class="top_right">
            <p @click="zlMore()">更多</p>
            <img src="../../static/giftIndex/arrowRight.png" alt="">
          </div>
        </div>

        <div class="list">
          <div v-for="(item,index) in zlList" :key="index" class="list_item" @click="zlDetail(item.id)">
            <div class="item_top">
              <img :src="item.img" alt="">
            </div>
            <div class="item_bottom">
              <div class="title_box">
                <p class="title">{{ item.gift_name }}</p>
              </div>
              <div class="zlBtm" style="justify-content: space-between">
                <div style="display: flex;align-items: center;">
                  <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
                  <p class="myPrice">
                    <span style="display: inline-block;margin-right: 2px">{{ item.quantity }}</span>
                    <!-- <span style="font-size: 15px">起</span> -->
                  </p>
                </div>
                <p class="stock" style="padding:0 !important;">库存<span>{{ item.num }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="is_zlList" class="main_list">
        <div class="main_top">
          <div class="top_left">
            <p>京东优选</p>
            <img src="../../static/giftIndex/titBg.png" alt="">
          </div>
          <div class="top_right">
            <p @click="jdMore()">更多</p>
            <img src="../../static/giftIndex/arrowRight.png" alt="">
          </div>
        </div>
        <div class="list">
          <div v-for="(item,index) in jdList" :key="index" class="list_item" @click="jdDetail(item)">
            <div class="item_top">
              <img :src="item.img" alt="">
            </div>
            <div class="item_bottom">
              <div class="title_box">
                <p class="title">{{ item.gift_name }}</p>
              </div>
              <div class="zlBtm">
                <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
                <p class="myPrice">{{ item.quantity }}</p>
                <p class="stock">库存<span>{{ item.num }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="is_searchList" class="main_list">
        <div class="main_top">
          <div class="top_left">
            <p>搜索结果</p>
            <img src="../../static/giftIndex/titBg.png" alt="">
          </div>
        </div>
        <div class="list">
          <div v-for="(item,index) in searchList" :key="index" class="list_item" @click="jdDetail(item)">
            <div class="item_top">
              <img :src="item.img" alt="">
            </div>
            <div class="item_bottom">
              <div class="title_box">
                <p class="title">{{ item.gift_name }}</p>
              </div>
              <div class="zlBtm">
                <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
                <p class="myPrice">{{ item.quantity }}</p>
                <p class="stock">库存<span>{{ item.num }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { get_gift, getCategory, getMyScore, getRow } from '@/api/my_user.js'
import { getAppCommonStatis } from '@/api/hometop.js'
import { Toast } from 'vant'
import tool from '@/utils/tools'
import WechatState from '@/utils/isWechatState.js'

export default {
  data() {
    return {
      show: false,
      moneyName: '',
      zlList: [],
      jdList: [],
      CategoryList: [],
      searchMsg: '',
      money: 0,
      flList: [],
      searchList: [],
      is_zlList: true,
      is_searchList: false
    }
  },
  mounted() {
    this.getScoreName()
    this.getMsg()
    this.get_Category()
    this.MyScore()
    // 小程序打开的话,头部小箭头隐藏
    // if (WechatState.isWechatMiniprogram) {
    //   this.$refs.imgShow.style = 'visibility:hidden;'
    // }
    this.getAppCommonStatis()
  },
  methods: {
    getScoreName() {
      const p = { type: 1 }
      getRow(p).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const name = result.name
          if (name) {
            this.moneyName = name
          }
        }
      })
    },
    Resets() {
      this.flList = []
      this.CategoryList.forEach(item => {
        if (item.checked === 1) {
          item.checked = 0
        }
      })
    },
    delItem(v, i) {
      v.checked = 0
      this.flList.splice(i, 1)
    },
    // 筑龙币查询
    MyScore() {
      getMyScore().then(res => {
        this.money = res.result
      })
    },

    // 跳搜索
    goSearch() {
      if (!this.searchMsg) {
        Toast('没有关键词')
      } else {
        this.$router.push({
          path: '/searchResult',
          query: {
            msg: this.searchMsg
          }
        })
      }
    },
    // 搜索框展示内容
    CategoryListPush(item, index) {
      if (!item.checked) {
        item.checked = 1
        this.flList.push(item)
      } else {
        item.checked = 0
        this.flList.forEach((items, i) => {
          if (items.id === item.id) {
            this.flList.splice(i, 1)
          }
        })
      }

      // console.log(item, index)
      // if (!item.checked && this.flList <= 1) {
      //   item.checked = 1
      //   this.flList.push(item)
      // } else {
      //   item.checked = 0
      //   this.flList.forEach((items, i) => {
      //     if (items.id === item.id) {
      //       this.flList.splice(i, 1)
      //     }
      //   })
      // }
    },
    // 获取分类列表
    get_Category() {
      getCategory().then(res => {
        res.result.forEach((item) => {
          item.checked = 0
        })
        // console.log(res.result)
        this.CategoryList = res.result
      })
    },
    jdDetail(item) {
      console.log(item)
      // 原创
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: item.id
        }
      })
      /*if (item.gift_source == 1) {
        this.$router.push({
          path: '/giftinfo',
          query: {
            id: item.id
          }
        })
      } else {
        this.$router.push({
          path: '/gift/giftExchange',
          query: {
            id: item.id
          }
        })
      }*/
    },
    zlDetail(id) {
      console.log(id)
      /*this.$router.push({
        path: '/giftinfo',
        query: {
          id: id
        }
      })*/
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: id
        }
      })
    },
    getMsg() {
      get_gift().then(res => {
        this.zlList = res.result.zlProduct.slice(0, 4)
        this.jdList = res.result.jdProduct.slice(0, 4)
      })
    },
    jdMore() {
      this.$router.push({
        path: '/jdList'
      })
    },
    zlMore() {
      this.$router.push({
        path: '/zlList'
      })
    },
    ExchangeRecord() {
      this.$router.push({
        path: '/ExchangeRecord'
      })
    },
    showPopup(type) {
      if (type === 1) {
        this.show = true
      } else if (type === 2) {
        this.show = false
      } else if (type === 3) {
        this.is_zlList = false
        this.show = false
        const idList = []
        this.flList.forEach((item, index) => {
          idList.push(item.id)
        })
        var params = {
          // category_id: JSON.stringify(idList)
          // category_id: idList[0]
        }
        if (idList && idList.length > 0) {
          params.category_id = JSON.stringify(idList)
        }
        get_gift(params).then(res => {
          // console.log(res)
          // this.zlList = res.result.zlProduct.slice(0, 4)
          // this.jdList = res.result.jdProduct.slice(0, 4)
          // console.log(this.jdList)
          if (idList.length > 0) {
            // this.jdList = res.result.list
            this.searchList = res.result.zlProduct
            this.is_searchList = true
          } else {
            this.zlList = res.result.zlProduct.slice(0, 4)
            this.jdList = res.result.jdProduct.slice(0, 4)
            this.is_zlList = true
            this.is_searchList = false
          }
        })
      }
    },
    goBack() {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
            'vue_backApp',
            '返回',
            function(responseData) {

            }
          )
        }
      } else {
        this.$router.go(-1)
      }
    },
    go_detail() {
      this.$router.push({
        path: '/gift/giftExchange'
      })
    },
    // 三方统计
    getAppCommonStatis() {
      getAppCommonStatis().then(res => {
        console.log(res, 'res')
        this.statis = res.result
        // var bd_script = document.createElement('script')
        // bd_script.type = 'text/javascript'
        // bd_script.innerHTML = this.statis.baidu_h5
        // // console.log(this.statis, 'this.statis')
        // document.head.appendChild(bd_script)
        var cc_script = document.createElement('script')
        cc_script.type = 'text/javascript'
        cc_script.innerHTML = this.statis.circle
        document.head.appendChild(cc_script)
        var cz_script = document.createElement('script')
        cz_script.type = 'text/javascript'
        cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
        // cz_script.innerHTML = this.statis.cnzz
        document.head.appendChild(cz_script)
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.gift_main {
  width: 100%;
}
.main_list {
  width: 100%;
}
.main_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_left {
  // width: 141px;
  width: 160px;
  margin-left: 30px;
  position: relative;
}
.top_left p {
  font-size: 34px;
  color: #222222;
  font-weight: 600;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.top_left img {
  width: 135px;
  height: 13px;
  position: absolute;
  bottom: 10px;
}

.top_right {
  display: flex;
  margin-right: 30px;
  p {
    color: #888888;
    padding: 0 15px 0 0;
  }
  img {
    width: 11px;
    height: 18px;
    padding: 9px 0 0 0;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.list_item {
  width: 330px;
  margin: 30px 0 0 30px;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
}
.item_top {
  width: 100%;
  height: 330px;
  img {
    width: 100%;
    height: 330px;
    border-radius: 10px 10px 0 0;
  }
}
.item_bottom {
  padding: 16px 16px 0 16px;
}
.title_box {
  height: 90px;
}
.title {
  color: #333333;
  font-size: 30px;
  // height: 94px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
.zlBtm {
  display: flex;
  align-items: center;
  margin: 18px 0 18px 0;
  height: 30px;
}
.mmon {
  width: 26px;
  height: 30px;
}
.myPrice {
  color: #ee2e2e;
  font-size: 36px;
  padding: 0 0 0 10px;
}
.stock {
  color: #999999;
  font-size: 28px;
}

.popupAll {
  width: 100%;
  height: 100%;
  position: relative;
}
.popupBtn_item {
  width: 93%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 60px;
  left: 30px;
  .Reset {
    width: 330px;
    height: 80px;
    color: #222222;
    font-size: 30px;
    letter-spacing: 2px;
    background: #f3f3f3;
    border-radius: 10px;
  }
  .complete {
    width: 330px;
    height: 80px;
    background: #ee2e2e;
    border: 1px solid #ee2e2e;
    // border-radius: 6px;
    border-radius: 10px;
    font-size: 30px;
    color: #fff;
    letter-spacing: 2px;
  }
}
.popupBtn {
  width: 100%;
}
.classification_p {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 25px 30px;
}
.classification_item {
  display: flex;
  width: 93%;
  margin: 0 auto;
  flex-wrap: wrap;
  // justify-content: space-between;
  .pGrey {
    // width: 152px;
    width: 148px;
    background: #f7f7f7;
    // border-radius: 6px;
    border-radius: 10px;
    color: #666666;
    text-align: center;
    line-height: 62px;
    border: 2px solid #f7f7f7;
    margin-top: 10px;
    margin-left: 20px;
  }

  .pRed {
    width: 148px;
    // width: 152px;
    // border-radius: 6px;
    border-radius: 10px;
    background: #ffefef;
    border: 2px solid #ff7576;
    color: #ee2e2f;
    text-align: center;
    line-height: 62px;
    margin-top: 10px;
    margin-left: 20px;
  }
}
.partition2 {
  width: 100%;
  height: 15px;
  background: #f5f5f5;
  margin: 30px 0 0 0;
}
.pushClass {
  height: 44px;
  background: #fff;
  display: inline-block;
  margin: 0 0 10px 15px;
  line-height: 44px;
  // padding: 0 13px 0 13px;
  padding: 0 13px 0 13px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.popupSearch {
  margin: 30px 0 0 0;
}
.searchSpan {
  color: #565759;
  font-size: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  // padding: 13px 0 0 10px;
  display: inline-block;
  width: 80px;
  height: 64px;
  line-height: 64px;
  text-align: center;
}
.SearchBox {
  width: 620px;
  min-height: 56px;
  border-radius: 30px;
  background: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}
.Search_ipt {
  width: 91%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.popupHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 0 0;
  img {
    width: 68px;
    height: 68px;
    margin-left: 10px;
  }
}
.popupTitle {
  font-size: 36px;
  color: #222222;
  // font-weight: 600;
  font-weight: bold;
  padding: 0 30px 0 0;
  letter-spacing: 3px;
  text-align: center;
}
.partition {
  width: 100%;
  height: 10px;
  background: #eeeeee;
}
.main_jd {
  // width: 93%;
  // margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: darkgoldenrod;
}
.zl_main {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  background-color: cyan;
}
.stock {
  padding: 0 0 0 20px;
  span {
    padding: 0 0 0 6px;
  }
}
.commodityTitle {
  // color: #222222;
  color: #333333;
  font-size: 30px;
  // font-weight: 600;
  line-height: 40px;
  background-color: blueviolet;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  min-height: 100px;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
// .mmon{
//   width: 26px;
//   height: 30px;
// }
// .myPrice{
//   color: #FF0000;
//   font-size: 32px;
//   font-weight: 600;
//   padding: 0 0 0 10px;
// }
// .stock{
//   color: #888888;
//   font-size: 26px;
// }
// .zlBtm{
//   display: flex;
//   align-items: center;
//   margin: 10px 0 0 0 ;
// }
.zl_mains {
  // width: 330px;
  margin: 30px 20px 0 0;
  // margin-top: 30px;
  background-color: cornflowerblue;
}
.zl_mainsTop {
  background-color: chocolate;
}
.giftImg {
  // width: 330px;
  // height: 330px;
  width: 325px;
  height: 325px;
  background: antiquewhite;
}
.main {
  width: 100%;
}
.zlTitle_right {
  display: flex;
  p {
    color: #888888;
    padding: 0 15px 0 0;
  }
  img {
    width: 11px;
    height: 18px;
    padding: 9px 0 0 0;
  }
}
.main_zl {
  width: 93%;
  margin: 0 auto;
  margin-bottom: 30px;
  // background: antiquewhite;
}
.zlTitle {
  display: flex;
  justify-content: space-between;
}
.zlTitle_left {
  width: 141px;
  height: 35px;
  position: relative;
}
.zlTitle_left p {
  font-size: 34px;
  color: #222222;
  font-weight: 600;
}
.zlTitle_left img {
  width: 135px;
  height: 13px;
  position: absolute;
  bottom: -4px;
}
.myMoneyRight button {
  width: 160px;
  height: 60px;
  background: #ee2e2f;
  color: #fefefe;
  border-radius: 30px;
  margin: 20px 30px 0 0;
  font-size: 22px;
}
.myMoneyLeft p {
  font-size: 50px;
  // font-weight: 600;
  color: #333333;
  height: 104px;
  line-height: 104px;
  padding: 0 0 0 30px;
  span {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    padding: 0px 10px 0 0;
    position: relative;
    bottom: 8px;
  }
}
.myMoney {
  width: 92%;
  height: 104px;
  background: #fff;
  position: absolute;
  // bottom: 80px;
  bottom: 40px;
  left: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}
.searchRight {
  width: 561px;
  height: 70px;
  position: relative;
  margin: 0 0 0 0px;
  img {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 30px;
    top: 21px;
  }
}
.searchRight input {
  width: 491px;
  height: 66px;
  border-radius: 75px;
  padding: 0 0 0 70px;
  color: #777777;
  // letter-spacing: 1px;
  font-size: 24px;
  display: flex;
  align-items: center;
}
.search {
  width: 100%;
  display: flex;
  align-items: center;
}
.searchLeft img {
  width: 38px;
  height: 38px;
}
.searchLeft {
  width: 130px;
  display: flex;
  align-items: center;
  margin: 0 0 0 30px;
  p {
    font-size: 30px;
    color: #fff;
    padding: 0 0 0 12px;
  }
}
.myHeader {
  width: 100%;
  // height: 360px;
  height: 320px;
  background: url("../../static/giftIndex/topRadius.png") no-repeat;
  // background-size: 100% 75%;
  background-size: 100% 80%;
  position: relative;
  overflow: hidden;
}
.navImg {
  // width: 20px;
  // height: 34px;
  width: 45px;
  height: 60px;
  padding: 0px 0 0px 20px;
  position: relative;
  bottom: 3px;
}
.main_title {
  font-size: 36px;
  color: #fff;
  padding-left: 88px;
  letter-spacing: 2px;
  font-weight: 500;
}
.ExchangeRecord {
  font-size: 28px;
  color: #fff;
  padding: 5px 30px 0 0;
}
.nav {
  width: 100%;
  height: 71px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;
}
</style>
